import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import rootStore from '@/app/store'
import { ImpersonationRequest } from '@/features/impersonation/types'
import { useNavigate } from 'react-router-dom'
import { Icon, Icons } from '@/ui/icon'
import { toast } from 'react-toastify'

const ImpersonationPage: React.FC = observer(() => {
  const { impersonation, auth } = rootStore
  const [userId, setUserId] = useState('')
  const [reason, setReason] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.user || auth.user.role !== 'admin') {
      navigate('/')
    }
  }, [auth.user, navigate])

  // Load impersonatable users when the component mounts
  useEffect(() => {
    impersonation.loadImpersonatableUsers()
  }, [impersonation])

  // Find the currently selected user object
  const selectedUser = userId
    ? impersonation.impersonatableUsers.find((user) => user.id === userId)
    : null

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!userId) {
      toast.error('Por favor, selecciona un usuario para suplantar')
      return
    }

    setIsSubmitting(true)

    try {
      const data: ImpersonationRequest = {
        userId,
        reason: reason.trim() || 'Administrative purpose',
      }

      const success = await impersonation.startImpersonation(data)

      if (success) {
        toast.success(`Estás suplantando al usuario ${userId}`)
        navigate('/explore', { replace: true })
      }
    } catch (error) {
      console.error('Error durante la suplantación:', error)
      toast.error(
        'Error al iniciar la suplantación. Por favor, inténtalo de nuevo.',
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const filteredUsers = searchQuery
    ? impersonation.impersonatableUsers.filter(
        (user) =>
          user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.id.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : impersonation.impersonatableUsers

  return (
    <div className="impersonation-page">
      <div className="impersonation-page-header">
        <Icon name={Icons.User} size={24} />
        <h1 className="impersonation-page-title">Suplantación de Usuario</h1>
      </div>

      <div className="impersonation-card">
        <h2 className="impersonation-page-title">Suplantar a un Usuario</h2>
        <p className="impersonation-banner-text">
          Esta función permite a los administradores acceder temporalmente al
          sistema como otro usuario para fines de mantenimiento o resolución de
          problemas. Todas las acciones serán registradas por motivos de
          seguridad.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="impersonation-form-group">
            <label className="impersonation-form-label">Buscar Usuarios</label>
            <input
              type="text"
              className="impersonation-form-input"
              placeholder="Buscar por email o ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="impersonation-form-group">
            <label className="impersonation-form-label">
              Seleccionar Usuario
            </label>
            <select
              className="impersonation-form-select"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            >
              <option value="">Selecciona un usuario para suplantar...</option>
              {filteredUsers.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.email} ({user.id})
                </option>
              ))}
            </select>

            {selectedUser && (
              <div className="impersonation-selected-user">
                <div className="impersonation-selected-user-header">
                  <Icon name={Icons.User} size={16} />
                  <span>Usuario Seleccionado:</span>
                </div>
                <div className="impersonation-selected-user-details">
                  <div className="impersonation-selected-user-email">
                    {selectedUser.email}
                  </div>
                  <div className="impersonation-selected-user-id">
                    ID: {selectedUser.id}
                  </div>
                  <div className="impersonation-selected-user-role">
                    Rol: {selectedUser.role}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="impersonation-form-group">
            <label className="impersonation-form-label">
              Motivo de la Suplantación
            </label>
            <textarea
              className="impersonation-form-textarea"
              rows={3}
              placeholder="Proporciona un motivo para esta suplantación..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
            <p className="impersonation-banner-text">
              Esto quedará registrado por motivos de seguridad.
            </p>
          </div>

          <div className="impersonation-form-submit-container">
            <button
              type="submit"
              disabled={isSubmitting || impersonation.loading || !userId}
              className="impersonation-form-submit"
            >
              {isSubmitting || impersonation.loading
                ? 'Procesando...'
                : selectedUser
                  ? `Suplantar a ${selectedUser.email.split('@')[0]}`
                  : 'Iniciar Suplantación'}
            </button>
          </div>
        </form>

        <div className="mt-8 text-sm text-gray-600">
          <h3 className="font-bold mb-2">Notas Importantes de Seguridad:</h3>
          <ul className="list-disc pl-5 space-y-1">
            <li>Las sesiones de suplantación están limitadas a 1 hora</li>
            <li>
              Todas las acciones realizadas durante la suplantación quedan
              registradas
            </li>
            <li>Finaliza la sesión tan pronto como completes tu tarea</li>
            <li>
              Por razones de seguridad, algunas acciones sensibles pueden estar
              restringidas
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
})

export default ImpersonationPage
