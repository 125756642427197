import { z } from 'zod'

export type User = {
  id: string
  email: string
  createdAt: string
  updatedAt: string
  role: string
  organization: string
  active: boolean
  chatTokensLeft: number
  publicFlags?: UserPublicFlag[]
  needsKDForm?: boolean
}

export type SignupData = {
  email: string
  password: string
  company?: string
}

export const OTPStatusSchema = z.enum(['required', 'sent', 'allowed'])

export type OTPStatus = z.infer<typeof OTPStatusSchema>

export type UserPublicFlag =
  | 'used-tool'
  | 'generated-image'
  | 'analyzed-document'
  | 'invited-teammate'
  | 'added-org-info'
  | 'invite-teammate-dismissed'
