import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from '../../app/store'
import {
  checkAuth,
  checkOTPRequirement,
  login,
  logout,
  requestOTPCode,
  signup,
  updateUserPublicFlags,
  verify,
  verifyOTPCode,
} from './api'
import { OTPStatus, SignupData, UserPublicFlag } from './types'
import { updateUserData } from '../tools/api'
import { toast } from 'react-toastify'
import { logoutErp } from '../erp/api'
import { captureException } from '@sentry/react'

export class AuthStore {
  rootStore: RootStore
  token: string | undefined
  user: any = undefined

  requestingOTP = false
  verifyingOTP = false

  otpStatus: OTPStatus | undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setUser(user: any) {
    if (!user) {
      this.user = undefined
      return
    }
    const formattedUser = {
      ...user,
      favoriteTools: user.favoriteTools?.map((t: any) => t.id) || [],
    }
    this.user = formattedUser
  }

  public async updateUserData(data: any) {
    this.user = { ...this.user, ...data }
    await updateUserData(this.user.id, data)
  }

  public async login(email: string, password: string) {
    const data = await login(email, password)
    if (data.token) {
      this.token = data.token
      this.setUser(data.user)
    }

    return data
  }

  public async logout() {
    await logoutErp().catch((error) => {
      captureException(error)
      // Shallow error to continue logging out
    })

    await logout()
    this.token = undefined
    this.setUser(undefined)
  }

  public async signup(data: SignupData) {
    const result = await signup(data)
    if (result.token) {
      this.token = result.token
      this.setUser(result.user)
    }

    return result
  }

  public async checkAuth() {
    const data = await checkAuth()

    if (data.token) {
      this.token = data.token
      this.setUser(data.user)
    }

    return data
  }

  public async verify(token: string) {
    const data = await verify(token)
    return data
  }

  public async checkOTPRequirement() {
    const data = await checkOTPRequirement()

    runInAction(() => {
      this.otpStatus = data
    })

    return data
  }

  public async requestOTP() {
    this.requestingOTP = true

    const otpStatus = await requestOTPCode()

    if (otpStatus === 'sent') {
      toast.success('Código enviado correctamente.')
    }

    runInAction(() => {
      this.requestingOTP = false
      this.otpStatus = otpStatus
    })
  }

  public async verifyOTP(otp: string) {
    this.verifyingOTP = true

    const otpStatus = await verifyOTPCode(otp)

    runInAction(() => {
      this.otpStatus = otpStatus
      this.verifyingOTP = false
    })
  }

  public async addUserPublicFlag(flag: UserPublicFlag) {
    return updateUserPublicFlags([flag]).then(() => {
      runInAction(() => {
        this.user.publicFlags = [...(this.user?.publicFlags || []), flag]
      })
    })
  }

  public async updateUserPublicFlags(flags: UserPublicFlag[]) {
    return updateUserPublicFlags(flags).then(() => {
      runInAction(() => {
        this.user.publicFlags = [...(this.user?.publicFlags || []), ...flags]
      })
    })
  }
}
