import { fetchJSON } from '@/utils/http'
import { API_ROUTE } from '../backend/config'
import { ImpersonationRequest, ImpersonationResponse } from './types'

export const startImpersonation = async (
  data: ImpersonationRequest,
): Promise<ImpersonationResponse> => {
  return await fetchJSON(`${API_ROUTE}/impersonate`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const endImpersonation = async (): Promise<ImpersonationResponse> => {
  return await fetchJSON(`${API_ROUTE}/impersonate/end`, {
    method: 'POST',
  })
}

export const getImpersonatableUsers = async (): Promise<any[]> => {
  const response = await fetchJSON(`${API_ROUTE}/users?limit=10000`, {
    method: 'GET',
  })
  return response.docs
}
