import { Link } from 'react-router-dom'
import TransitionLayout from '@/ui/TransitionLayout'
import EmptyState from '@/ui/EmptyState'
import animation from '@/ui/lotties/Nomad.json'

type UserNotActivePageProps = {
  isManager: boolean
}

const UserNotActivePage = ({ isManager }: UserNotActivePageProps) => {
  return (
    <TransitionLayout>
      <div className="mobile-layout">
        <EmptyState animation={animation} title="Usuario desactivado">
          {isManager ? (
            <p className="empty-text">
              Contrata un plan desde la pagina de tus{' '}
              <Link to="/subscriptions">subscripciones</Link> y activa tu
              cuenta.
            </p>
          ) : (
            <p className="empty-text">
              Por favor contacta el administrador de tu organization para
              reactivar tu cuenta. Si tienes dudas contacta con AiKit.
            </p>
          )}
        </EmptyState>
      </div>
    </TransitionLayout>
  )
}

export default UserNotActivePage
