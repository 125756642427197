import { makeAutoObservable } from 'mobx'
import { RootStore } from '@/app/store'
import { getImpersonatableUsers, startImpersonation } from './api'
import { ImpersonationRequest } from './types'

export class ImpersonationStore {
  rootStore: RootStore
  isImpersonating = false
  impersonatableUsers: any[] = []
  loading = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get isAdmin(): boolean {
    return this.rootStore.auth.user?.role === 'admin'
  }

  get isImpersonated(): boolean {
    return !!this.rootStore.auth.user?._impersonatedBy
  }

  async startImpersonation(data: ImpersonationRequest): Promise<boolean> {
    if (!this.isAdmin) {
      return false
    }

    this.loading = true

    try {
      const response = await startImpersonation(data)

      if (response.impersonatedUser) {
        this.isImpersonating = true

        // Reload the current user to reflect the impersonation
        await this.rootStore.auth.checkAuth()

        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    } finally {
      this.loading = false
    }
  }

  async loadImpersonatableUsers(): Promise<void> {
    if (!this.isAdmin) {
      return
    }

    this.loading = true

    try {
      const users = await getImpersonatableUsers()
      this.impersonatableUsers = users
    } catch (error) {
      console.error('Error al cargar los usuarios', error)
    } finally {
      this.loading = false
    }
  }
}
