import { useState, useEffect } from 'react'
import { autoLoginErp } from './api'
import { ERPLoadingState } from './config'

export const useErpAutoLogin = () => {
  const [loadingState, setLoadingState] = useState<ERPLoadingState>(
    ERPLoadingState.Loading,
  )

  useEffect(() => {
    const performAutoLogin = async () => {
      try {
        setLoadingState(ERPLoadingState.Loading)
        const data = await autoLoginErp()

        // Session is now created on the Odoo side.
        console.log('Auto-login successful:', data)

        setLoadingState(ERPLoadingState.Loaded)
      } catch (error) {
        console.error('Error during auto login:', error)
        setLoadingState(ERPLoadingState.Error)
      }
    }

    performAutoLogin()
  }, [])

  return loadingState
}
