import rootStore from '@/app/store'
import React from 'react'
import cx from 'classnames'
import { truncate } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import { Icon, Icons } from '@/ui/icon'
import { useDialogRef } from '@/utils/dialog'
import CTA from '@/ui/CTA'
import Dialog from '@/ui/dialogs/Dialog'
import ShortcutInfo from '@/components/shortcuts/ShortcutInfo'
import ShortcutButton from '@/components/shortcuts/ShortcutButton'

const MENU_LINKS = {
  stats: {
    href: '/stats',
    icon: Icons.Stats,
    label: 'Estadísticas',
  },
  api: {
    href: '/api',
    icon: Icons.Code,
    label: 'API',
  },
  documents: {
    href: '/knowledge-bases/global',
    icon: Icons.Archive,
    label: 'Documentos AiKit',
  },
  history: {
    href: '/history',
    icon: Icons.History,
    label: 'Historial',
  },
  support: {
    href: '/support',
    icon: Icons.Help,
    label: 'Soporte',
  },
} as const

type MenuLinkKey = keyof typeof MENU_LINKS
type UserRole = 'admin' | 'org-manager' | 'user'

const ROLE_LINKS: Record<UserRole, MenuLinkKey[]> = {
  admin: ['documents', 'history', 'support'],
  'org-manager': ['stats', 'history', 'support'],
  user: ['history', 'support'],
} as const

const isValidUserRole = (role: string): role is UserRole => {
  return ['admin', 'org-manager', 'user'].includes(role)
}

const getUserRole = (role: string): UserRole => {
  if (isValidUserRole(role)) {
    return role
  }
  return 'user'
}

type UserMenuProps = {
  'data-cy'?: string
}

const UserMenu = ({ 'data-cy': dataCy }: UserMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [showTooltip, setShowTooltip] = React.useState(false)
  const menuRef = React.useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const { auth, tools, organizations, knowledgeBases, chat, tool } = rootStore

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClose = () => setIsOpen(false)

  const handleLogout = () => {
    auth.logout().then(() => {
      tools.resetState()
      chat.resetState()
      tool.resetState()
      organizations.resetState()
      knowledgeBases.resetState()

      navigate('/auth/login?redirect=/home')

      handleClose()
    })
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleClose()
      }
    }

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [isOpen])

  React.useEffect(() => {
    ;(window as any).showUserMenuTooltip = () => {
      setShowTooltip(true)
    }
    ;(window as any).hideUserMenuTooltip = () => {
      setShowTooltip(false)
    }

    return () => {
      delete (window as any).showUserMenuTooltip
      delete (window as any).hideUserMenuTooltip
    }
  }, [])

  const userRole = getUserRole(auth.user.role)

  const navAndClose = (path: string) => {
    navigate(path)
    handleClose()
  }

  const {
    dialogRef: editDialogRef,
    handleOpen: handleOpenEditDialog,
    handleClose: handleCloseEditDialog,
  } = useDialogRef()

  const isMac = () => {
    return (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.userAgent.toUpperCase().indexOf('MAC') >= 0
    )
  }

  const getModifierKey = () => {
    return isMac() ? { icon: Icons.Cmd } : { text: 'Ctrl' }
  }

  return (
    <>
      <div className="userMenu" ref={menuRef} data-cy={dataCy}>
        <button
          className="userMenu-button"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}
          data-cy={dataCy ? `${dataCy}-button` : undefined}
        >
          <div aria-hidden="true" className="userMenu-avatar">
            <Icon size={28} name={Icons.User} />
          </div>
          <span className="userMenu-name">
            {truncate(auth.user.email.split('@')[0], 16).toLocaleLowerCase()}
          </span>
          <Icon
            className={cx('userMenu-icon', isOpen && 'is-open')}
            size={14}
            name={Icons.ChevronDown}
          />
          <div
            className={cx('userMenu-tooltip', {
              'is-visible': showTooltip,
            })}
          >
            {auth.user.organizationName && (
              <div
                className="userMenu-tooltip-org"
                style={{ fontWeight: 'bold', marginBottom: '4px' }}
              >
                {auth.user.organizationName}
              </div>
            )}
            <div>{auth.user.email}</div>
          </div>
        </button>

        <div
          className={cx('userMenu-wrapper', isOpen && 'is-open')}
          aria-hidden={!isOpen}
          data-cy={dataCy ? `${dataCy}-dropdown` : undefined}
        >
          <ul className="userMenu-list">
            {/* {auth.user.active ? (
                <li>Creditos: {auth.user.chatTokensLeft}</li>
            ) : null} */}

            {ROLE_LINKS[userRole].map((linkKey: MenuLinkKey) => (
              <li key={linkKey}>
                <NavLink
                  href={MENU_LINKS[linkKey].href}
                  icon={MENU_LINKS[linkKey].icon}
                  onNavigate={() => navAndClose(MENU_LINKS[linkKey].href)}
                  data-cy={dataCy ? `${dataCy}-${linkKey}` : undefined}
                >
                  {MENU_LINKS[linkKey].label}
                </NavLink>
              </li>
            ))}
            <li>
              <button
                className="userMenu-link"
                onClick={handleOpenEditDialog}
                role="menuitem"
                data-cy={dataCy ? `${dataCy}-edit` : undefined}
              >
                <Icon size={14} name={Icons.Cmd} />
                Atajos de teclado
              </button>
            </li>
            <li>
              <button
                className="userMenu-link"
                onClick={handleLogout}
                role="menuitem"
                data-cy={dataCy ? `${dataCy}-logout` : undefined}
              >
                <Icon size={14} name={Icons.Logout} />
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Dialog
        ref={editDialogRef}
        icon={Icons.Cmd}
        title="Atajos de teclado"
        text="A continuación podrás ver una lista completa de todos los atajos que puedes utilizar para manjear AiKit desde tu teclato."
        onClose={handleCloseEditDialog}
        actions={
          <CTA variant="default" size="medium" onClick={handleCloseEditDialog}>
            Cerrar
          </CTA>
        }
      >
        <div>
          <ShortcutInfo
            title="Nuevo chat"
            description="Inicia una nueva conversación desde cualquier lugar."
          >
            <ShortcutButton {...getModifierKey()} />
            <ShortcutButton text="I" />
          </ShortcutInfo>
          <ShortcutInfo
            title="Cambiar modo razonador"
            description="Activa o desactiva el modo de razonamiento del chat."
          >
            <ShortcutButton {...getModifierKey()} />
            <ShortcutButton text="M" />
          </ShortcutInfo>
          <ShortcutInfo
            title="Abrir el historial"
            description="Accede al historial desde cualquier lugar."
          >
            <ShortcutButton {...getModifierKey()} />
            <ShortcutButton text="H" />
          </ShortcutInfo>
          <ShortcutInfo
            title="Volver a empezar"
            description="Vuelve al inicio de una herramienta para comenzar desde cero."
          >
            <ShortcutButton {...getModifierKey()} />
            <ShortcutButton icon={Icons.ArrowLeft} />
          </ShortcutInfo>
          <ShortcutInfo
            title="Editar resultado"
            description="Abre el modo edición dentro de una herramienta."
          >
            <ShortcutButton {...getModifierKey()} />
            <ShortcutButton text="E" />
          </ShortcutInfo>
        </div>
      </Dialog>
    </>
  )
}

export default UserMenu

interface NavLinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  icon: string
  href: string
  children: React.ReactNode
  onNavigate: () => void
  'data-cy'?: string
}

const NavLink: React.FC<NavLinkProps> = ({
  icon,
  href,
  children,
  onNavigate,
  'data-cy': dataCy,
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onNavigate()
  }

  return (
    <a
      className="userMenu-link"
      href={href}
      onClick={handleClick}
      role="menuitem"
      data-cy={dataCy}
      {...rest}
    >
      <Icon size={14} name={icon} />
      <span>{children}</span>
    </a>
  )
}
