import { RefObject, useEffect, useRef } from 'react'

export const getDialogHandlers = (dialogRef: RefObject<HTMLDialogElement>) => {
  const handleOpen = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal()
    }
  }

  const handleClose = () => {
    if (dialogRef.current) {
      const dialog = dialogRef.current
      dialog.classList.add('closing')

      dialog.addEventListener(
        'animationend',
        () => {
          dialog.close()
          dialog.classList.remove('closing')
        },
        { once: true },
      )
    }
  }

  return { handleOpen, handleClose }
}

export const useDialogRef = () => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const { handleOpen, handleClose } = getDialogHandlers(dialogRef)

  return { dialogRef, handleOpen, handleClose }
}

export const useListenDialogOpen = (
  dialogRef: RefObject<HTMLDialogElement>,
  callback: () => void,
) => {
  useEffect(() => {
    if (!dialogRef?.current) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'open' && dialogRef.current?.open) {
          callback()
        }
      })
    })

    observer.observe(dialogRef.current, { attributes: true })

    return () => observer.disconnect()
  }, [dialogRef])
}

export const useListenDialogClose = (
  dialogRef: RefObject<HTMLDialogElement>,
  callback: () => void,
) => {
  useEffect(() => {
    if (!dialogRef?.current) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'open' && !dialogRef.current?.open) {
          callback()
        }
      })
    })

    observer.observe(dialogRef.current, { attributes: true })

    return () => observer.disconnect()
  }, [dialogRef])
}
