import './lib/sentry'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'

import App from './app/App'
import './assets/stylesheets/style.scss'
import './ui/stylesheets/index.css'
import ReactGA from 'react-ga4'

import { GA_MEASUREMENT_ID } from './features/backend/config'
import { toJS } from 'mobx'
import { SentryErrorBoundary } from './app/SentryErrorBoundary'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { queryClient } from './lib/react-query'
import { ExportMessageDialogProvider } from './contexts/ExportMessageDialogContext'

ReactGA.initialize(GA_MEASUREMENT_ID)

// for debugging purposes
;(window as any)['tojs'] = toJS;
// Expose react-toastify for use in inline JS
import { toast } from 'react-toastify';
(window as any)['ReactToastify'] = { toast }

const stylesCache = createCache({
  key: 'css',
  nonce: 'emotion',
  prepend: true,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <SentryErrorBoundary>
      <CacheProvider value={stylesCache}>
        <ExportMessageDialogProvider>
          <App />
        </ExportMessageDialogProvider>
      </CacheProvider>
    </SentryErrorBoundary>
  </QueryClientProvider>,
)

// <React.StrictMode>
