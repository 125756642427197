import { observer } from 'mobx-react'
import { Icons, Icon } from '../icon'
import React, { forwardRef, useEffect } from 'react'
import cx from 'classnames'
import RoundButton from '../RoundButton'
import {
  getDialogHandlers,
  useListenDialogClose,
  useListenDialogOpen,
} from '@/utils/dialog'
import { kebabCase } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

interface DialogProps {
  isArticle?: boolean
  isRow?: boolean
  headerImage?: string
  icon?: string
  title: string
  text?: string
  children?: React.ReactNode
  actions?: React.ReactNode
  onClose: () => void
}

const Dialog = forwardRef<HTMLDialogElement, Omit<DialogProps, 'ref'>>(
  (props, ref) => {
    const {
      isArticle,
      isRow,
      headerImage,
      icon,
      title,
      text,
      children,
      actions,
      onClose,
    } = props

    const navigate = useNavigate()
    const location = useLocation()

    const { handleOpen } = getDialogHandlers(
      ref as React.RefObject<HTMLDialogElement>,
    )

    useEffect(() => {
      // Open dialog if URL hash matches this dialog's title. ONLY ON INITIAL LOAD.
      if (location.hash === `#${kebabCase(title)}`) {
        handleOpen()
      }
    }, [])

    const navigatePreservingParams = (hash?: string) => {
      let url = `${location.search}`

      if (hash) {
        url += `${hash}`
      }

      navigate(url)
    }

    useListenDialogOpen(ref as React.RefObject<HTMLDialogElement>, () => {
      navigatePreservingParams(`#${kebabCase(title)}`)
    })

    useListenDialogClose(ref as React.RefObject<HTMLDialogElement>, () => {
      navigatePreservingParams('#')
    })

    // Handle browser back button.
    // Open dialog when new URL has this dialog's hash.
    // Close dialog when hash gets removed from URL.
    useEffect(() => {
      const handleHashChange = (ev: HashChangeEvent) => {
        const dialogElement = ref as React.RefObject<HTMLDialogElement>

        if (!dialogElement.current) {
          return
        }

        const { oldURL, newURL } = ev
        const dialogHash = `#${kebabCase(title)}`

        const hashRemoved =
          oldURL.includes(dialogHash) && !newURL.includes(dialogHash)

        const hashAdded =
          !oldURL.includes(dialogHash) && newURL.includes(dialogHash)

        if (hashAdded) {
          handleOpen()
        } else if (hashRemoved) {
          onClose()
        }
      }

      window.addEventListener('hashchange', handleHashChange)

      return () => {
        window.removeEventListener('hashchange', handleHashChange)
      }
    }, [])

    const handleClickOutside = (event: React.MouseEvent) => {
      const dialogElement = ref as React.RefObject<HTMLDialogElement>
      if (event.target === dialogElement.current) {
        onClose()
      }
    }

    return (
      <dialog
        ref={ref}
        className={cx('dialog', {
          dialogArticle: isArticle,
        })}
        onKeyDown={(event) => event.key === 'Escape' && onClose()}
        onClick={handleClickOutside}
      >
        <div className="dialog-wrapper">
          {isArticle ? (
            <header className="dialogArticle-header">
              <div className="dialogArticle-headerImage">
                <img src={headerImage} alt="Imagen de cabecera" />
              </div>
              <h2 className="dialogArticle-title">
                {icon ? <Icon size={24} name={icon} /> : null}
                {title}
              </h2>
              <RoundButton
                icon={Icons.Cross}
                className="dialog-close"
                label="Cerrar cuadro de diálogo"
                onClick={onClose}
              />
            </header>
          ) : (
            <header className="dialog-header">
              <RoundButton
                icon={Icons.Cross}
                className="dialog-close"
                label="Cerrar cuadro de diálogo"
                onClick={onClose}
              />
              <h2 className="dialog-title">
                {icon ? <Icon size={24} name={icon} /> : null}
                {title}
              </h2>
              {text ? <p className="dialog-text">{text}</p> : null}
            </header>
          )}
          {children ? (
            <div
              className={cx('dialog-content', {
                'is-row': isRow,
              })}
            >
              {children}
            </div>
          ) : null}
          {actions ? (
            <footer className="dialog-footer">{actions}</footer>
          ) : null}
        </div>
      </dialog>
    )
  },
)

Dialog.displayName = 'Dialog'

export default observer(Dialog)
