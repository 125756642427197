import { observer } from 'mobx-react'
import { Icons, Icon } from '@/ui/icon'

type IconType = (typeof Icons)[keyof typeof Icons]

interface ShortcutButtonProps {
  icon?: IconType
  text?: string
}

const ShortcutButton = ({ icon, text }: ShortcutButtonProps) => {
  return (
    <div className="shortcutButton">
      {icon ? (
        <Icon size={14} name={icon} />
      ) : (
        <span className="shortcutButton-text">{text}</span>
      )}
    </div>
  )
}

export default observer(ShortcutButton)
