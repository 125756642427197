import { RootStore } from '@/app/store'
import { makeAutoObservable, runInAction } from 'mobx'
import * as api from './api'
import {
  CompanyPublicInfo,
  ListMembersResponse,
  OrgDailyStats,
  PendingMemberInvite,
  PlanInfo,
} from './types'

export type EditablePlanInfo = {
  hasRAGEnabled: boolean
}

export class OrganizationsStore {
  rootStore: RootStore

  initialLoadingPlan = false
  planInfo: PlanInfo | undefined = undefined
  membersData: ListMembersResponse | undefined = undefined
  pendingInvites: PendingMemberInvite[] | undefined = undefined
  sendingMemberInvite = false

  isLoadingPublicInfo = false
  publicInfo: CompanyPublicInfo | undefined = undefined

  isLoadingUserLogins = false
  userLogins: api.LoginEvent[] = []

  isLoadingOrganizations = false
  organizations: api.Organization[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  // NOTE: Called on logout
  public resetState() {
    this.isLoadingPublicInfo = false
    this.planInfo = undefined
    this.membersData = undefined
    this.publicInfo = undefined
  }

  async fetchStats(interval: number) {
    const stats: OrgDailyStats[] = await api.fetchStats(interval)
    return stats
  }

  async fetchPlan() {
    // NOTE: Initial loading state is only set if the plan info is not already loaded
    if (!this.planInfo) {
      this.initialLoadingPlan = true
    }

    try {
      const { plan, members, pendingInvites } = await api.fetchPlan()

      runInAction(() => {
        this.planInfo = plan
        this.membersData = members
        this.pendingInvites = pendingInvites
      })

      return members
    } finally {
      runInAction(() => {
        this.initialLoadingPlan = false
      })
    }
  }

  async inviteMember(email: string) {
    this.sendingMemberInvite = true

    await api.inviteMember(email).finally(() => {
      runInAction(() => {
        this.sendingMemberInvite = false
      })
    })
  }

  async updateMember(memberId: string, params: api.UpdateMemberParams) {
    await api.updateMember(memberId, params)

    await this.fetchPlan()

    if (memberId === this.rootStore.auth.user?.id) {
      await this.rootStore.auth.checkAuth()
    }
  }

  async updatePlan(values: EditablePlanInfo) {
    await api.updatePlan(values)
    await this.fetchPlan()
  }

  async autocompleteCompanyInfo(files: File[]) {
    const suggestedData = await api.autocompleteCompanyInfoFromFiles(files)

    return suggestedData
  }

  async fetchCompanyInfo() {
    this.isLoadingPublicInfo = true

    try {
      const data: CompanyPublicInfo = await api.fetchPublicCompanyInfo()

      runInAction(() => {
        this.publicInfo = data
      })
    } finally {
      runInAction(() => {
        this.isLoadingPublicInfo = false
      })
    }
  }

  async saveCompanyInfo(data: CompanyPublicInfo) {
    await api.updateCompanyInfo(data)

    await this.fetchCompanyInfo()

    await this.fetchPlan()
  }

  async fetchOrgUserLogins(organizationId: string, limit: number = 100) {
    this.isLoadingUserLogins = true

    try {
      const logins = await api.fetchOrgUserLogins(organizationId, limit)

      runInAction(() => {
        this.userLogins = logins
      })

      return logins
    } finally {
      runInAction(() => {
        this.isLoadingUserLogins = false
      })
    }
  }

  async fetchOrganizations() {
    this.isLoadingOrganizations = true

    try {
      const organizations = await api.fetchOrganizations()

      runInAction(() => {
        this.organizations = organizations
      })

      return organizations
    } finally {
      runInAction(() => {
        this.isLoadingOrganizations = false
      })
    }
  }
}
