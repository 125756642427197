const Toolset = Object.freeze({
  Abstract1: 'abstract1',
  Abstract2: 'abstract2',
  Abstract3: 'abstract3',
  Abstract4: 'abstract4',
  Abstract5: 'abstract5',
  Abstract6: 'abstract6',
  Abstract7: 'abstract7',
  Abstract8: 'abstract8',
  Abstract9: 'abstract9',
  Abstract10: 'abstract10',
  Abstract11: 'abstract11',
  Abstract12: 'abstract12',
  Abstract13: 'abstract13',
  Abstract14: 'abstract14',
  Abstract15: 'abstract15',
  Abstract16: 'abstract16',
  Abstract17: 'abstract17',
  Abstract18: 'abstract18',
  Abstract19: 'abstract19',
  Abstract20: 'abstract20',
})

export const Icons = Object.freeze({
  ...Toolset,

  Agents: 'agents',
  AiKit: 'ai-kit',
  Archive: 'archive',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
  ArrowUp: 'arrow-up',
  Brain: 'knowledge',
  Book: 'book',
  Calendar: 'calendar',
  Categories: 'categories',
  Chat: 'chat',
  Check: 'check',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  Clip: 'clip',
  Code: 'code',
  Cmd: 'cmd',
  Copy: 'copy',
  Cross: 'cross',
  Document: 'document',
  Download: 'download',
  Edit: 'edit',
  Error: 'error',
  Expand: 'expand',
  Explore: 'explore',
  Export: 'export',
  Fav: 'fav',
  FavAdd: 'fav-add',
  FavRemove: 'fav-remove',
  Group: 'group',
  Help: 'help',
  History: 'history',
  Home: 'home',
  Image: 'image',
  Logout: 'logout',
  Magic: 'magic',
  MagicList: 'magic-list',
  Magnifying: 'zoom',
  Menu: 'menu',
  ModelClaude: 'model-claude',
  ModelDeepSeek: 'model-deepseek',
  ModelGemini: 'model-gemini',
  ModelGPT: 'model-gpt',
  Pause: 'pause',
  Play: 'play',
  Refresh: 'refresh',
  Resources: 'resources',
  Select: 'select',
  Send: 'send',
  Settings: 'settings',
  Share: 'share',
  Smart: 'smart',
  Sidebar: 'sidebar',
  Stats: 'stats',
  StepBack: 'step-back',
  StepForward: 'step-forward',
  Stop: 'stop',
  ThumbDown: 'thumb-down',
  ThumbDownFilled: 'thumb-down-filled',
  ThumbUp: 'thumb-up',
  ThumbUpFilled: 'thumb-up-filled',
  Trash: 'trash',
  Type: 'type',
  Upload: 'upload',
  User: 'user',
  Volume: 'volume',
  VolumeMuted: 'volume-muted',
  Warning: 'warning',
  Write: 'write',
})

export const Patterns = Object.freeze({
  Circles: 'circles',
  Flowers: 'flowers',
  Gear: 'gear',
  GearRound: 'gear-round',
  Net: 'net',
  Sun: 'sun',
})
