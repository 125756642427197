export const ERP_BASE_URL =
  import.meta.env.VITE_ERP_BASE || 'https://erp.aikit.es'

export enum ERPLoadingState {
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
  Maintenance = 'maintenance',
}

export enum ERP_APPS {
  invoicing = 'invoicing',
  chat = 'chat',
  planner = 'planner',
}

export const ERP_APP_URLS = {
  [ERP_APPS.invoicing]: `${ERP_BASE_URL}/odoo/customer-invoices`,
  [ERP_APPS.chat]: `${ERP_BASE_URL}/odoo/discuss`,
  [ERP_APPS.planner]: `${ERP_BASE_URL}/odoo/project`,
}

export type ERPCompanyInfo = {
  tax_id: string
  street: string
  city: string
  zip: string
  state_code: string
  logo?: string
  report_footer: string

  primary_color?: string
  secondary_color?: string
}

const PROVINCES = [
  { id: 420, code: 'A', name: 'Alacant (Alicante)' },
  { id: 419, code: 'AB', name: 'Albacete' },
  { id: 421, code: 'AL', name: 'Almería' },
  { id: 423, code: 'AV', name: 'Ávila' },
  { id: 426, code: 'B', name: 'Barcelona' },
  { id: 424, code: 'BA', name: 'Badajoz' },
  { id: 466, code: 'BI', name: 'Bizkaia (Vizcaya)' },
  { id: 427, code: 'BU', name: 'Burgos' },
  { id: 417, code: 'C', name: 'A Coruña (La Coruña)' },
  { id: 429, code: 'CA', name: 'Cádiz' },
  { id: 428, code: 'CC', name: 'Cáceres' },
  { id: 432, code: 'CE', name: 'Ceuta' },
  { id: 434, code: 'CO', name: 'Córdoba' },
  { id: 433, code: 'CR', name: 'Ciudad Real' },
  { id: 431, code: 'CS', name: 'Castelló (Castellón)' },
  { id: 435, code: 'CU', name: 'Cuenca' },
  { id: 444, code: 'GC', name: 'Las Palmas' },
  { id: 436, code: 'GI', name: 'Girona (Gerona)' },
  { id: 437, code: 'GR', name: 'Granada' },
  { id: 438, code: 'GU', name: 'Guadalajara' },
  { id: 440, code: 'H', name: 'Huelva' },
  { id: 441, code: 'HU', name: 'Huesca' },
  { id: 442, code: 'J', name: 'Jaén' },
  { id: 446, code: 'L', name: 'Lleida (Lérida)' },
  { id: 445, code: 'LE', name: 'León' },
  { id: 443, code: 'LO', name: 'La Rioja' },
  { id: 447, code: 'LU', name: 'Lugo' },
  { id: 448, code: 'M', name: 'Madrid' },
  { id: 449, code: 'MA', name: 'Málaga' },
  { id: 450, code: 'ME', name: 'Melilla' },
  { id: 451, code: 'MU', name: 'Murcia' },
  { id: 452, code: 'NA', name: 'Navarra (Nafarroa)' },
  { id: 422, code: 'O', name: 'Asturias' },
  { id: 453, code: 'OR', name: 'Ourense (Orense)' },
  { id: 454, code: 'P', name: 'Palencia' },
  { id: 425, code: 'PM', name: 'Illes Balears (Islas Baleares)' },
  { id: 455, code: 'PO', name: 'Pontevedra' },
  { id: 430, code: 'S', name: 'Cantabria' },
  { id: 456, code: 'SA', name: 'Salamanca' },
  { id: 459, code: 'SE', name: 'Sevilla' },
  { id: 458, code: 'SG', name: 'Segovia' },
  { id: 460, code: 'SO', name: 'Soria' },
  { id: 439, code: 'SS', name: 'Gipuzkoa (Guipúzcoa)' },
  { id: 461, code: 'T', name: 'Tarragona' },
  { id: 462, code: 'TE', name: 'Teruel' },
  { id: 457, code: 'TF', name: 'Santa Cruz de Tenerife' },
  { id: 463, code: 'TO', name: 'Toledo' },
  { id: 464, code: 'V', name: 'Valencia (Valencia)' },
  { id: 465, code: 'VA', name: 'Valladolid' },
  { id: 418, code: 'VI', name: 'Araba/Álava' },
  { id: 468, code: 'Z', name: 'Zaragoza' },
  { id: 467, code: 'ZA', name: 'Zamora' },
]

export const PROVINCES_OPTIONS = PROVINCES.map((province) => ({
  value: province.code,
  label: province.name,
})).sort((a, b) => a.label.localeCompare(b.label))

export const PROVINCE_CODE_TO_NAME = PROVINCES.reduce(
  (acc, province) => {
    acc[province.code] = province.name
    return acc
  },
  {} as Record<string, string>,
)
