import React from 'react'
import { observer } from 'mobx-react'

interface ShortcutInfoProps {
  title: string
  description: string
  children: React.ReactNode
}

const ShortcutInfo = ({ title, description, children }: ShortcutInfoProps) => {
  return (
    <div className="shortcutInfo">
      <div className="shortcutInfo-buttons">{children}</div>
      <div className="shortcutInfo-text">
        <p className="shortcutInfo-title">{title}</p>
        <p className="shortcutInfo-description">{description}</p>
      </div>
    </div>
  )
}

export default observer(ShortcutInfo)
