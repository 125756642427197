import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAuthRoutes } from './routes'
import rootStore from './store'
import MainLayout from '@/ui/MainLayout'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLocation,
  useRouteError,
} from 'react-router-dom'

// import ClarityTracker from '@/features/analytics/clarity'
import { observer } from 'mobx-react'
import { useFlushTrackingEventsBeforeUnload } from '@/features/tracking/hooks'

import { cssTransition, ToastContainer } from 'react-toastify'
import LinearProgress from '@/ui/LinearProgress'
import 'react-toastify/dist/ReactToastify.css'
import { Icon } from '@/ui/icon'
import { setUserId } from '@/features/analytics/google'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)

// NOTE: Rethrow for SentryErrorBoundary to capture it
const BrowserErrorBoundary = () => {
  const error = useRouteError()

  if (error instanceof Error) {
    if (error.message.includes('dynamically imported module')) {
      window.location.reload()
      return null
    }

    throw error
  }

  return null
}

const RedirectToLogin = () => {
  const location = useLocation()

  return <Navigate to={`/auth/login?redirect=${location.pathname}`} />
}

const App = observer(() => {
  const { auth } = rootStore
  const [checkedAuth, setcheckedAuth] = useState(false)

  useFlushTrackingEventsBeforeUnload()

  useEffect(() => {
    auth.checkAuth().then((data) => {
      setcheckedAuth(true)
      setUserId(data.user?.id)
    })
  }, [])

  const loggedInUser = auth.user
  const authRoutes = useAuthRoutes(loggedInUser)

  const skipKDForm =
    !loggedInUser?.needsKDForm ||
    !!new URLSearchParams(window.location.search).get('skipkdform') ||
    document.cookie
      .split(';')
      .some((cookie) => cookie.trim().indexOf('skipkdform=true') === 0)

  if (!checkedAuth) {
    return (
      <div className="top-loading-bar">
        <LinearProgress />
      </div>
    )
  }

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route ErrorBoundary={BrowserErrorBoundary}>
        {/* NOTE: LEGACY PAGES
        <Route path="/auth/signup" lazy={() => import('./pages/SignupPage')} />
        <Route
          path="/auth/quick-signup"
          lazy={() => import('./pages/auth/QuickSignupPage')}
        /> */}

        <Route path="/auth/login" lazy={() => import('./pages/SigninPage')} />
        <Route
          path="/auth/verify/:token"
          lazy={() => import('./pages/auth/VerificationPage')}
        />

        <Route
          path="/platform-invite"
          lazy={() => import('./pages/auth/PlatformInvitePage')}
        />

        <Route
          path="/accept-invite"
          lazy={() => import('./pages/auth/AcceptInvitePage')}
        />

        <Route
          path="/forgot-password"
          lazy={() => import('./pages/auth/ForgotPasswordPage')}
        />
        <Route
          path="/reset-password"
          lazy={() => import('./pages/auth/ResetPasswordPage')}
        />

        {/* TODO: LANDING REMOVED
        <Route path="/landing" lazy={() => import('./pages/LandingPage')} />
        <Route path="/" lazy={() => import('./pages/LandingPage')} />
        <Route
          path="/"
          element={<ExternalRedirect url={'https://www.aiconsortivm.com'} />}
        /> */}

        {loggedInUser && authRoutes ? (
          !skipKDForm ? (
            <Route path="*" lazy={() => import('./pages/MultiStepLoginPage')} />
          ) : (
            <Route element={<MainLayout />}>{authRoutes}</Route>
          )
        ) : (
          <Route path="*" element={<RedirectToLogin />} />
        )}

        <Route
          path="/privacy-policy"
          lazy={() => import('./pages/PrivacyPolicyPage')}
        />
      </Route>,
    ),
  )

  return (
    <>
      <ToastContainer
        hideProgressBar
        icon={({ type }) => {
          switch (type) {
            case 'info':
              return <Icon size={20} name="help" />
            case 'error':
              return <Icon size={20} name="error" />
            case 'success':
              return <Icon size={20} name="check" />
            case 'warning':
              return <Icon size={20} name="warning" />
            default:
              return <Icon size={20} name="help" />
          }
        }}
        transition={cssTransition({
          enter: 'is-visible',
          exit: 'is-hidden',
        })}
      />
      {/* <KitDigitalPaymentNotice /> */}
      {/* <ClarityTracker /> */}
      <RouterProvider router={router} />
    </>
  )
})

export default App
