import { ToolType, ToolUseRequestData, ToolExecutionResult } from './types'
import { executeCode } from './code-execution'
import { FileResource } from './code-execution/types'

export async function handleToolUseRequest(
  request: ToolUseRequestData,
): Promise<ToolExecutionResult> {
  try {
    if (!request.data || typeof request.data !== 'object') {
      return {
        error: {
          message: 'Invalid request data',
        },
      }
    }

    if (!Object.values(ToolType).includes(request.toolType as ToolType)) {
      return {
        error: {
          message: `Unsupported tool type: ${request.toolType}`,
        },
      }
    }

    switch (request.toolType) {
      case ToolType.AnalysisTool:
        if (!request.data.code || typeof request.data.code !== 'string') {
          return {
            error: {
              message: 'No code provided for execution',
            },
          }
        }

        const files: FileResource[] = []
        if (request.data.files && Array.isArray(request.data.files)) {
          request.data.files.forEach((file) => {
            if (file && file.fileId && file.url) {
              files.push({
                name: file.fileId,
                url: file.url,
              })
            }
          })
        }

        return executeCode(request.data.code, {
          files,
          maxExecutionTime: 10000,
        })

      default:
        return {
          error: {
            message: `Unsupported tool type: ${request.toolType}`,
          },
        }
    }
  } catch (error) {
    console.error('Error handling tool use request:', error)
    return {
      error: {
        message:
          error instanceof Error ? error.message : 'Unknown error occurred',
        stack: error instanceof Error ? error.stack : undefined,
      },
    }
  }
}
