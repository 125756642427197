export enum ToolType {
  AnalysisTool = 'analysisTool',
}

export interface ToolUseRequestData {
  threadId: string
  signalId: string
  toolType: ToolType
  data: {
    [key: string]: any
    code?: string
    fileUrls?: Array<{
      name: string
      url: string
    }>
  }
}

export interface ToolExecutionResult {
  logs?: Array<{
    type: 'log' | 'warn' | 'error'
    message: string
  }>
  result?: any
  error?: {
    message: string
    stack?: string
  }
}
