import cx from 'classnames'
import Spinner from './Spinner'

interface ExportButtonProps {
  isActive?: boolean
  isLoading?: boolean
  isReady?: boolean
  logo: string
  text: string
  caption?: string
  onClick?: () => void
}

const ExportButton = ({
  isActive,
  isLoading,
  isReady,
  logo,
  text,
  caption,
  onClick,
}: ExportButtonProps) => {
  return (
    <button
      type="button"
      className={cx('exportButton', {
        'is-active': isActive,
        'is-loading': isLoading,
        'is-ready': isReady,
      })}
      onClick={onClick}
      disabled={isLoading}
    >
      <div className="exportButton-logoWrapper">
        <img className="exportButton-logo" src={logo} alt="Logo" />
        <Spinner isVisible={isLoading} />
      </div>
      <p className="exportButton-text">{text}</p>
      {caption && <p className="exportButton-caption">{caption}</p>}
    </button>
  )
}

export default ExportButton
