import { useState } from 'react'
import {
  ERP_APP_URLS,
  ERP_APPS,
  ERPLoadingState,
  ERP_BASE_URL,
} from '@/features/erp/config'
import { useErpAutoLogin } from '@/features/erp/hooks'
import EmptyState from '@/ui/EmptyState'
import Spinner from '@/ui/Spinner'
import animation from '@/ui/lotties/Analytics.json'

export const ErpEmbed = ({ page }: { page: ERP_APPS }) => {
  const loadingState = useErpAutoLogin()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const handleIframeLoad = () => {
    setIframeLoaded(true)
  }
  return (
    <div className="odooIframe" key={page}>
      {loadingState === ERPLoadingState.Loaded && (
        <iframe
          key={page}
          src={ERP_APP_URLS[page]}
          data-cy="erp-iframe"
          title="ERP"
          allow={`camera ${ERP_BASE_URL}; microphone ${ERP_BASE_URL}`}
          sandbox="allow-scripts allow-same-origin allow-popups allow-downloads"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            display:
              loadingState === ERPLoadingState.Loaded && iframeLoaded
                ? 'block'
                : 'none',
          }}
          onLoad={handleIframeLoad}
        />
      )}

      {loadingState === ERPLoadingState.Error && (
        <div className="odooIframe-systemMessage">
          <EmptyState
            animation={animation}
            title="Parece que algo ha salido mal"
            text="Código de error: 500"
            hasButton
          />
        </div>
      )}
      {loadingState === ERPLoadingState.Maintenance && (
        <div className="odooIframe-systemMessage">
          <EmptyState
            animation={animation}
            title="Esta sección se encuentra en mantenimiento"
            text="Código de error: 503"
            hasButton
          />
        </div>
      )}

      {/* NOTE: The iframe must be loaded from the start so that the onload method can be called! */}
      {(loadingState === ERPLoadingState.Loading || !iframeLoaded) &&
        loadingState !== ERPLoadingState.Error &&
        loadingState !== ERPLoadingState.Maintenance && (
          <div className="odooIframe-systemMessage">
            <Spinner isVisible />
          </div>
        )}
    </div>
  )
}
