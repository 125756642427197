import rootStore from '@/app/store'
import { ERP_BASE_URL, ERPCompanyInfo } from './config'
import { toast } from 'react-toastify'

export const autoLoginErp = async () => {
  const { auth } = rootStore

  // HACK! Admin users have organization populated for some reason
  const orgId = auth.user.organization.id ?? auth.user.organization

  const response = await fetch(`${ERP_BASE_URL}/auth/auto_login`, {
    method: 'POST',
    headers: {
      'X-Odoo-dbfilter': `.*-${orgId}`,
      'x-payload-token': auth.token!,
    },
    credentials: 'include',
  })

  const responseJson = await response.json().catch(() => null)

  if (!response.ok) {
    throw new Error(`Failed to auto login in ERP: ${responseJson}`)
  }

  return responseJson
}

export const logoutErp = async () => {
  const response = await fetch(`${ERP_BASE_URL}/web/session/destroy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'call',
      params: {},
    }),
    credentials: 'include',
  })

  const responseJson = await response.json().catch(() => null)

  if (!response.ok || !responseJson || !responseJson.result?.success) {
    if (responseJson === null) {
      const responseText = await response.text().catch(() => null)
      throw new Error(`Failed to logout from ERP: ${responseText}`)
    }

    throw new Error(`Failed to logout from ERP: ${responseJson}`)
  }

  return responseJson
}

export const updateErpCompanyInfo = async (data: Partial<ERPCompanyInfo>) => {
  // Ensure we are logged in before calling the API
  const loginResponse = await autoLoginErp()
  if (!loginResponse.success) {
    throw new Error(`Failed to auto login in ERP: ${loginResponse}`)
  }

  const response = await fetch(`${ERP_BASE_URL}/aikit/update_company_info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'call',
      params: {
        ...data,
      },
    }),
    credentials: 'include',
  })

  const responseJson = await response.json().catch(() => null)

  if (!response.ok || !responseJson.result?.success) {
    // NOTE: This also catches NIE errors
    if (responseJson.result?.error?.includes('NIF')) {
      toast.error(
        'El número de NIF no es correcto. Por favor, inténtelo de nuevo.',
      )
    }

    throw new Error(`Failed to update ERP company info: ${responseJson}`)
  }

  return responseJson
}

export const getErpCompanyInfo = async () => {
  const loginResponse = await autoLoginErp()
  if (!loginResponse.success) {
    throw new Error(`Failed to auto login in ERP: ${loginResponse}`)
  }

  const response = await fetch(`${ERP_BASE_URL}/aikit/get_company_info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'call',
      params: {},
    }),
    credentials: 'include',
  })

  const responseJson = await response.json().catch(() => null)

  if (!response.ok || !responseJson.result?.success) {
    throw new Error(`Failed to get ERP company info: ${responseJson}`)
  }

  const companyInfo = responseJson.result?.company_info

  return companyInfo as ERPCompanyInfo
}
