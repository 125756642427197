import {
  FileResource,
  SandboxExecutionOptions,
  SandboxExecutionResult,
} from './types'

const fileCache = new Map<string, string>()

function createWorker(): Worker | null {
  try {
    const worker = new Worker(new URL('./sandbox.worker.ts', import.meta.url), {
      type: 'module',
    })
    return worker
  } catch (error) {
    console.error('Failed to initialize sandbox worker:', error)
    return null
  }
}

function terminateWorker(worker: Worker | null): void {
  if (worker) {
    worker.terminate()
  }
}

async function downloadFile(url: string): Promise<string> {
  if (fileCache.has(url)) {
    return fileCache.get(url)!
  }

  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Failed to download file: ${response.statusText}`)
  }
  const content = await response.text()

  fileCache.set(url, content)

  return content
}

// TODO: preventively load uploaded files into the cache to avoid downloading them again
async function loadFiles(files: FileResource[] = []): Promise<FileResource[]> {
  if (!files || files.length === 0) {
    return []
  }

  const preparedFiles: FileResource[] = []

  for (const file of files) {
    try {
      const content = await downloadFile(file.url)
      preparedFiles.push({
        ...file,
        content,
      })
    } catch (error) {
      console.error(`Failed to prepare file ${file.name}:`, error)
      // Continue with other files even if one fails
    }
  }

  return preparedFiles
}

export async function executeCode(
  code: string,
  options: SandboxExecutionOptions = {},
): Promise<SandboxExecutionResult> {
  const loadedFiles = await loadFiles(options.files)

  return new Promise((resolve) => {
    // NOTE: We create a new worker for each execution to ensure a clean environment
    const worker = createWorker()

    if (!worker) {
      resolve({
        error: {
          message: 'Failed to initialize sandbox worker',
        },
      })
      return
    }

    const maxExecutionTime = options.maxExecutionTime || 10000

    const timeoutId = setTimeout(() => {
      terminateWorker(worker)
      resolve({
        error: {
          message: 'Execution timed out after ' + maxExecutionTime + 'ms',
        },
      })
    }, maxExecutionTime)

    const fileMap = new Map<string, string>()
    loadedFiles.forEach((file) => {
      if (file.content) {
        fileMap.set(file.name, file.content)
      }
    })

    // Handle file system requests
    worker.onmessage = (event) => {
      const { type, id, path } = event.data

      if (type === 'fs-request' && id && path) {
        const content = fileMap.get(path)

        if (content) {
          worker.postMessage({
            type: 'fs-response',
            id,
            content,
          })
        } else {
          worker.postMessage({
            type: 'fs-response',
            id,
            error: `File not found: ${path}. Did you include it in the fileIds array?`,
          })
        }
        return
      }

      // Handle execution result
      if (type === 'result' || type === 'error') {
        clearTimeout(timeoutId)

        const { logs, result, error } = event.data

        resolve({
          logs: logs || [],
          result: type === 'result' ? result : undefined,
          error: error || undefined,
        })

        terminateWorker(worker)
      }
    }

    worker.postMessage({ code })
  })
}

export function clearFileCache(): void {
  fileCache.clear()
}
