import UserMenu from '@/components/auth/UserMenu'
import { observer } from 'mobx-react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import logo from '/images/aikit.svg'
import Link from '@/ui/Link'
import RoundButton from '@/ui/RoundButton'
import { Icon, Icons } from './icon'
import rootStore from '@/app/store'
import cx from 'classnames'
import { useEffect } from 'react'

const menu = [
  {
    to: '/explore',
    icon: Icons.Explore,
    text: 'Explora',
    'data-cy': 'menu-explore',
  },
  // { to: '/favorites', icon: Icons.Fav, text: 'Favoritos', 'data-cy': 'menu-favorites' },
  // { to: '/assistants', icon: Icons.Chat, text: 'Asistentes', 'data-cy': 'menu-assistants' },
  {
    to: '/use-cases',
    icon: Icons.Resources,
    text: 'Casos de uso',
    'data-cy': 'menu-use-cases',
  },
  {
    to: '/agents',
    icon: Icons.Agents,
    text: 'Agentes',
    'data-cy': 'menu-agents',
  },
]

const secondaryMenu = [
  {
    to: '/subscriptions',
    icon: Icons.Group,
    text: 'Organización',
    'data-cy': 'menu-organization',
  },
  {
    to: '/knowledge-bases',
    icon: Icons.Brain,
    text: 'Base de conocimiento',
    'data-cy': 'menu-knowledge-base',
  },
]

type SidebarProps = {
  onClickCollapse: () => void
}

const Sidebar = ({ onClickCollapse }: SidebarProps) => {
  const { auth, keybindings } = rootStore
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleNewThread = () => {
      rootStore.tool.maybeCancelGeneration()
      rootStore.chat.clearThread()
      if (location.pathname !== '/assistants') {
        navigate('/assistants')
      } else {
        navigate('/assistants', {
          replace: true,
          state: { reloadKey: location.state?.reloadKey },
        })
      }
    }

    const handleVisitHistoryPage = () => {
      navigate('/history')
    }

    const registryNewThread = keybindings.register({
      keys: ['ctrl+i', 'meta+i'],
      callback: handleNewThread,
    })

    const registryVisitHistoryPage = keybindings.register({
      keys: ['ctrl+h', 'meta+h'],
      callback: handleVisitHistoryPage,
    })

    return () => {
      keybindings.unregister(registryNewThread)
      keybindings.unregister(registryVisitHistoryPage)
    }
  }, [location, navigate])

  const isPathActive = (path: string) => {
    if (!path) return false

    if (path.startsWith('/toolsets/')) {
      return location.pathname.includes(path)
    }
    return location.pathname === path
  }

  return (
    <header className="sidebar">
      <div className="sidebar-header">
        <RouterLink className="sidebar-logo" to="/home">
          <img width="96" height="20" src={logo} alt="Logo AI Kit" />
        </RouterLink>
        <RoundButton
          icon={Icons.Menu}
          variant="contrast"
          label="Menu"
          onClick={onClickCollapse}
        />
      </div>
      <div className="sidebar-content">
        <nav className="sidebar-nav">
          <RouterLink
            className="sidebar-navButton"
            to="/assistants"
            state={{ reloadKey: new Date().getTime() }}
          >
            <Icon name={Icons.Write} />
            <span>Nuevo chat</span>
          </RouterLink>
          {menu.map((item) => (
            <Link
              key={item.to}
              to={item.to}
              className="sidebar-item"
              isActive={isPathActive(item.to)}
              iconName={item.icon}
              data-cy={item['data-cy']}
            >
              {item.text}
            </Link>
          ))}
          <div
            className={cx('sidebar-settings', {
              'is-notAdmin': auth.user?.role !== 'org-manager',
            })}
          >
            <UserMenu data-cy="menu-user" />
          </div>
          {auth.user?.role === 'org-manager' &&
            secondaryMenu.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className="sidebar-item"
                isActive={isPathActive(item.to)}
                iconName={item.icon}
                data-cy={item['data-cy']}
              >
                {item.text}
              </Link>
            ))}
          <span className="sidebar-version">v{APP_VERSION}</span>
        </nav>
      </div>
    </header>
  )
}

export default observer(Sidebar)
