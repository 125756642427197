import React from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { SENTRY_DSN } from '@/features/backend/config'
import rootStore from '@/app/store'
import { HTTPError } from '@/utils/error'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (hint.originalException instanceof HTTPError) {
      return null
    }

    const { auth, tool, chat } = rootStore

    event.user = {
      ...(event.user ?? {}),
      id: auth.user?.id,
      email: auth.user?.email,
    }

    event.tags = {
      ...(event.tags ?? {}),
      role: auth.user?.role,
      organization: auth.user?.organization?.id,
    }

    event.contexts = {
      ...(event.contexts ?? {}),
      tool: {
        data: tool.data,
        inputs: tool.inputs,
        generating: tool.generating,
        sendingMessage: tool.sendingMessage,
      },
    }

    if (chat.thread) {
      event.tags = {
        ...(event.tags ?? {}),
        thread: chat.thread._id,
      }
    }

    return event
  },
})

export const logAndCapture = (error: Error) => {
  Sentry.captureException(error)
  console.error(error)
}
